// estilos del datepicker editados
.cont-hora {
  color: #000000;
  font-size: .7em;
  background: #F0F0F0;
  padding: 7px 10px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;

  &.seleccionada {
    background: var(--primary);
    color: #FFFFFF;
    font-weight: bold;
  }
}

ngb-datepicker {
  width: 100%;
  outline: none !important;
  border: none !important;
}

.ngb-dp-month {
  width: 100%;
}

.custom-day {
  text-align: center;
  padding: 0.285rem 0.45rem;
  border-radius: 1rem;
  display: inline-block;
  // width: 2rem;
  font-size: .7em;
  color: #88949F;
}

.custom-day:hover {
  background-color: var(--primary) !important;
  color: #FFFFFF !important;
}

.custom-day.focused{
  background-color: var(--primary) !important;
  color: #FFFFFF !important;
}

.ngb-dp-week .ngb-dp-weekday {
  color: #000000 !important;
  font-size: .7em !important;
  font-style: normal !important;
  width: 100%;
}

.ngb-dp-day{
  width: 100% !important;
  text-align: center;
}

.diaActual {
  background-color: var(--primary) !important;
  border-radius: 1rem !important;
  color: #FFFFFF !important;
  font-weight: bold !important;
}

.hidden {
  display: none;
}

.has-task {
  background-color: #e6e6e6;
  border-radius: 1rem;
  color: #000000;
  font-weight: bold !important;
}

.convenciones {
  font-size: 0.75em;
}

ngb-datepicker .ngb-dp-navigation-select select.custom-select {
  border: none !important;
  background: #ffffff !important;
  outline: none !important;
  color: #000000;
  font-weight: 700;
  width: auto;
}

.ngb-dp-arrow .btn-link {
  color: #000000 !important;
}

ngb-datepicker .ngb-dp-header {
  padding-top: 0em !important;
  background: #ffffff !important;
}

ngb-datepicker-month-view .ngb-dp-week.ngb-dp-weekdays {
  background-color: #ffffff !important;
  border-bottom: none;
}

.ngb-dp-arrow button.btn {
  line-height: normal !important;
  height: auto !important;
}

.ngb-dp-arrow button.btn.btn-link.ngb-dp-arrow-btn .ngb-dp-navigation-chevron {
  font-size: .5em !important;
}

ngb-datepicker-navigation-select {
  margin-top: 4px;
}

.ngb-dp-day,
.ngb-dp-weekday,
.ngb-dp-week-number,
.ngb-dp-arrow-btn,
.custom-select {
  outline: none !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
  white-space: initial !important;
}

@media (max-width: 1024px) {
  .cont-hora {
    font-size: .9em;
    padding: 11px 11px;
  }

  // .convenciones {
  //   font-size: 1rem;
  // }
}

@media(max-width: 990px) {
  .custom-day {
    font-size: .9em;
  }

  .ngb-dp-week .ngb-dp-weekday {
    font-size: .9em !important;
  }

  .ngb-dp-day,
  .ngb-dp-weekday,
  .ngb-dp-week-number {
    width: 4rem !important;
    height: 3rem !important;
  }

  .ngb-dp-week .ngb-dp-weekday {
    font-size: .9em !important;
  }
}

@media(max-width: 415px) {

  .ngb-dp-day,
  .ngb-dp-weekday,
  .ngb-dp-week-number {
    width: 3.5rem !important;
    height: 3rem !important;
  }
}

@media(max-width: 321px) {

  .ngb-dp-day,
  .ngb-dp-weekday,
  .ngb-dp-week-number {
    width: 3rem !important;
    height: 3rem !important;
  }
}

/* PACE PLUGIN
-------------------------------------------------- */
.landing-page.pace .pace-progress {
  background: var(--white);
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: 2px;
  -webkit-transition: width 1s;
  -moz-transition: width 1s;
  -o-transition: width 1s;
  transition: width 1s;

}

.pace-inactive {
  display: none;
}

div.landing-page {
  color: var(--text-color);
  font-family: var(--font-family-poppins);
  background-color: var(--white);
}

.landing-page {

  button:focus {
    outline: 0;
  }

  .container {
    overflow: hidden;
  }

  span.navy {
    color: var(--primary);
  }

  p.text-color {
    color: var(--text-color);
  }

  a.navy-link {
    color: var(--primary);
    text-decoration: none;
  }

  a.navy-link:hover {
    color: var(--info);
  }

  section p {
    color: var(--gray);
    font-size: 15px;
    line-height: 1.25;
  }

  .cont-redes-sociales {
    text-align: right;
  }

  address {
    font-size: 15px;
    color: var(--white);
    text-align: left;
  }

  h1 {
    margin-top: 10px;
    font-size: 30px;
    font-weight: var(--font-family-extra-bold);
  }

  .navy-line {
    width: 60px;
    height: 1px;
    margin: 10px auto 0;
    border-bottom: 2px solid var(--primary);
  }

  /* CUSTOMIZE THE NAVBAR
  -------------------------------------------------- */
  .navbar {
    padding: 0 1rem;
  }

  .navbar-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 200;
  }

  /* Flip around the padding for proper display in narrow viewports */
  .navbar-wrapper>.container {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-wrapper .navbar {
    padding-right: 15px;
    padding-left: 15px;
  }

  .navbar-default.navbar-scroll {
    background-color: var(--white);
    border-color: var(--white);
    padding: 15px 0;
  }

  .navbar-default {
    background-color: transparent;
    border-color: transparent;
    transition: all 0.3s ease-in-out 0s;
  }

  .navbar-default .nav li a {
    color: var(--white);
    font-family: var(--font-family-poppins);
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 14px;
  }

  .navbar-nav>li>a {
    padding-top: 25px;
    border-top: 6px solid transparent;
  }

  .navbar-default .navbar-nav>.active>a,
  .navbar-default .navbar-nav>.active>a:hover {
    background: transparent;
    color: var(--white);
    border-top: 6px solid var(--primary);
  }

  .navbar-default .navbar-nav>li>a:hover,
  .navbar-default .navbar-nav>li>a:focus {
    color: var(--primary);
    background: inherit;
  }

  .navbar-default .navbar-nav>.active>a:focus {
    background: transparent;
    color: var(--white);
  }

  .navbar-default .navbar-nav>.active>a:focus {
    background: transparent;
    color: var(--white);
  }

  .navbar-default.navbar-scroll .navbar-nav>.active>a:focus {
    background: transparent;
    color: inherit;
  }

  .navbar-default .navbar-brand:hover,
  .navbar-default .navbar-brand:focus {
    background: var(--info);
    color: var(--white);
  }

  .navbar-default .navbar-brand {
    color: var(--white);
    height: auto;
    display: block;
    font-size: 14px;
    background: var(--primary);
    padding: 15px 20px 15px 20px;
    border-radius: 0 0 5px 5px;
    font-weight: 700;
    transition: all 0.3s ease-in-out 0s;
    margin-top: -16px;
  }

  .navbar-scroll.navbar-default .nav li a {
    color: var(--text-color);
  }

  .navbar-scroll.navbar-default .nav li a:hover {
    color: var(--primary);
  }

  .navbar-wrapper .navbar.navbar-scroll {
    padding-top: 0;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--border-color);
    border-radius: 0;
  }

  .nav.navbar-right {
    flex-direction: row;
  }

  .nav>li.active {
    border: none;
    background: inherit;
  }

  .nav>li>a {
    padding: 25px 10px 15px 10px;
  }

  .navbar-scroll .navbar-nav>li>a {
    padding: 20px 10px;
  }

  .navbar-default .navbar-nav>li .nav-link.active,
  .navbar-default .navbar-nav>li .nav-link.active:hover {
    border-top: 6px solid var(--info);
  }

  .navbar-fixed-top {
    border: none !important;
  }

  .navbar-fixed-top.navbar-scroll {
    border-bottom: 1px solid var(--border-color) !important;
  }

  .navbar.navbar-scroll .navbar-brand {
    margin-top: 5px;
    border-radius: 5px;
    font-size: 12px;
    padding: 10px;
    height: auto;
  }

  /* BACKGROUNDS SLIDER
  -------------------------------------------------- */
  .header-back {
    height: 700px;
    width: 100%;
    // background-image: url("/assets/img/landing/header_one.jpg");
    background-image: url("/assets/img/landing/slides/fondo_slide.jpg");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: cover;
    // cuando sea dispositivo movil poner estilo
    // background-attachment: fixed;
  }

  // .header-back.one {
  //   background: url('../img/header_one.jpg') 50% 0 no-repeat;
  //   /* header_one.jpg */
  // }
  // .header-back.two {
  //   background: url('../img/header_two.jpg') 66% 0 no-repeat;
  // }
  // .header-back.three {
  //   background: url('../img/header_three.jpg') 50% 0 no-repeat;
  // }
  // .header-back.four {
  //   background: url('../img/header_four.png') 70% 0 no-repeat;
  // }

  /* CUSTOMIZE THE CAROUSEL
  -------------------------------------------------- */
  /* Carousel base class */
  .carousel {
    // height: 470px;
    height: 606px;
    overflow: hidden
  }

  /* Since positioning the image, we need to help out the caption */
  .carousel-caption {
    z-index: 10;
  }

  /* Declare heights because of positioning of img element */
  .carousel .item {
    height: 470px;
    background-color: #777;
  }

  .carousel-inner>img {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    height: 470px;
  }

  .carousel-fade .carousel-inner .item {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
  }

  .carousel-fade .carousel-inner .active {
    opacity: 1;
  }

  .carousel-fade .carousel-inner .active.left,
  .carousel-fade .carousel-inner .active.right {
    left: 0;
    opacity: 0;
    z-index: 1;
  }

  .carousel-fade .carousel-inner .next.left,
  .carousel-fade .carousel-inner .prev.right {
    opacity: 1;
  }

  .carousel-fade .carousel-control {
    z-index: 2;
  }

  .carousel-control.left,
  .carousel-control.right {
    background: none;
  }

  .carousel-control {
    width: 6%;
  }

  .carousel-inner .container {
    position: relative;
    overflow: visible;
  }

  .carousel-inner {
    overflow: visible;
  }

  .carousel-caption {
    position: absolute;
    top: 100px;

    &.caption-left {
      left: 0;
      bottom: auto;
      right: auto;
      text-align: left;
    }

    &.caption-right {
      right: 0;
      bottom: auto;
      left: auto;
      text-align: right;
    }

    &.caption-center {
      right: 0;
      bottom: 0;
      left: 0;
      text-align: center;
      top: 90px;
    }

    &.caption-responsive {}

    .blank {
      top: 140px;
    }

    span {
      color: var(--info);
      font-size: 17px;
    }

    h1 {
      margin-top: -5px;
      font-weight: var(--font-family-extra-bold);
      font-size: 50px;
      text-shadow: none;
      letter-spacing: -1.2px;
    }

    p {
      font-weight: var(--font-family-regular);
      text-shadow: none;
      font-size: 20px;
    }
  }

  .carousel-image {
    position: absolute;

    img {
      height: 540px;
    }
  }

  .carousel-image {
    &.ilustracion-uno {
      right: -40px;
      top: 80px;
    }

    &.ilustracion-dos {
      left: 80px;
      top: 80px;
    }

    &.ilustracion-tres {
      left: 30px;
      top: 212px;

      img {
        height: 400px;
      }
    }

    &.ilustracion-cuatro {
      right: -55px;
      top: 61px;
    }
  }

  .carousel-indicators {
    padding-right: 60px;
  }

  .caption-link {
    color: var(--white);
    margin-left: 10px;
    text-transform: capitalize;
    font-weight: 400;
  }

  .caption-link:hover {
    text-decoration: none;
    color: inherit;
  }

  /* Sections
  ------------------------- */
  @media (min-width: 1901px) {
    // .container {
    //   max-width: 1310px;
    // }
  }

  #fondoFeatures {
    background-image: url("/assets/img/landing/caracteristicas/fondoCirculos.png");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;

    .row .card .card-body .card-text {
      font-size: 13px;
      line-height: 1.3em;
    }
  }

  .bgCirculo {
    background-image: url("/assets/img/landing/caracteristicas/circulo-m.png");
    background-repeat: no-repeat;
    background-position: top right;
    width: 100%;
    height: 100%;
    position: absolute !important;
    // margin-left: 224px;
  }

  .items-img-descripcion {
    display: inline-block;

    &.teamAgendate {
      margin-left: 0px;
    }

    img {
      float: left;
      position: absolute;

      &.small {
        width: 45px;
        margin-top: 5px;
      }

      &.medium {
        width: 90px;
        margin-top: -10px;
      }

      &.team {
        width: 145px;
        margin-top: 0px;
      }
    }

    .cont-descripcion {
      &.small-des {
        float: left;
        margin-left: 60px;
      }

      &.medium-des {
        float: left;
        margin-left: 105px;
      }

      &.team-des {
        float: left;
        margin-left: 160px;
        margin-top: 30px;

        .cargo-team {
          color: var(--info);
          font-size: .8em;
          line-height: 1.1;
          margin-bottom: 2px;
        }

        .nombre-team {
          color: var(--white);
          font-weight: var(--font-family-extra-bold);
          font-size: .99em;
          letter-spacing: 1.02px;
        }
      }

      p.descripcion{
        color: var(--gray);
        font-size: .73em;
        line-height: 1.25;
        font-weight: 400;
      }
    }
  }

  .services {
    padding-top: 60px;

    h2 {
      font-size: 20px;
      letter-spacing: -1px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .card-title-services {
    position: absolute;
    width: 100%;
    text-align: center;
    margin-top: 2em;

    h2 {
      color: var(--white);
      font-weight: var(--font-family-semi-bold);
      letter-spacing: 0.2px;
    }
  }

  .card-img-services {
    -webkit-border-top-left-radius: 2em;
    -webkit-border-top-right-radius: 2em;
    -moz-border-radius-topleft: 2em;
    -moz-border-radius-topright: 2em;
    border-top-left-radius: 2em;
    border-top-right-radius: 2em;
  }

  .card-border-radius {
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;

    -webkit-box-shadow: 5px 7px 5px 0px rgba(214, 214, 214, 1);
    -moz-box-shadow: 5px 7px 5px 0px rgba(214, 214, 214, 1);
    box-shadow: 5px 7px 5px 0px rgba(214, 214, 214, 1);
  }

  .contFondoTextura {
    background-image: url("/assets/img/landing/slides/fondo_slide.jpg");
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: cover;
    padding-top: 60px;
    // height: 100vh !important;
    height: 912px;
  }

  .contFondoTexturaPlanes {
    background-image: url("/assets/img/landing/slides/fondo_slide.jpg");
    background-repeat: no-repeat;
    background-position: top;
    padding-top: 60px;
  }

  .contFondoTexturaFooter {
    background-image: url("/assets/img/landing/fondo_footer.png");
    background-repeat: no-repeat;
    background-position: top;
    padding-top: 60px;
  }

  .padding-titulo-planes {
    padding-bottom: 2rem;
    padding-top: 1rem;
  }

  .features-block {
    margin-top: 40px;
  }

  .features-text {
    margin-top: 40px;
  }

  .features {
    h2 {
      font-size: 18px;
      margin-top: 5px;
    }

    small {
      color: var(--primary);
    }

    .text-features-left{
      font-size: 1.2em;
    }

    .text-features-right{
      font-size: 1.3em;
    }
  }

  .features-text-alone {
    margin: 40px 0;
  }

  .features-text-alone h1 {
    font-weight: 200;
  }

  .features-icon {
    color: var(--primary);
    font-size: 40px;
  }

  .navy-section {
    margin-top: 60px;
    background: var(--primary);
    color: var(--white);
    padding: 20px 0;
  }

  .cont-titlo-team {
    text-align: right;
  }

  .gray-section {
    background: #f4f4f4;
    margin-top: 60px;
  }

  .team-member {
    text-align: center;
  }

  .team-member img {
    margin: auto;
  }

  .social-icon a {
    background: var(--info);
    color: var(--white);
    padding: 4px 6px;
    height: 28px;
    width: 28px;
    display: block;
    border-radius: 50px;
  }

  .social-icon a:hover {
    background: var(--primary);
  }

  .img-small {
    height: 145px;
    width: 145px;

    transition: width 2s, height 2s, transform 2s;
    -moz-transition: width 2s, height 2s, -moz-transform 2s;
    -webkit-transition: width 2s, height 2s, -webkit-transform 2s;
    -o-transition: width 2s, height 2s, -o-transform 2s;
  }

  .img-small:hover {
    /* tranformamos el elemento al pasar el mouse por encima al doble de su tamaño con scale(2). */
    transform: scale(2);
    -moz-transform: scale(2);
    /* Firefox */
    -webkit-transform: scale(2);
    /* Chrome - Safari */
    -o-transform: scale(2);
    /* Opera */
  }

  .pricing-plan {
    margin: 20px 30px 0 30px;
    // border-radius: 4px;
    background: var(--white);
    -webkit-box-shadow: 7px 8px 11px -8px rgba(16, 73, 162, 0.56);
    -moz-box-shadow: 7px 8px 11px -8px rgba(16, 73, 162, 0.56);
    box-shadow: 7px 8px 11px -8px rgba(16, 73, 162, 0.56);

    &.selected {
      transform: scale(1.2);
    }

    & li {
      padding: 10px 16px;
      border-top: 1px solid var(border-color);
      text-align: left;
      color: #999999;

      &.pricing-desc {
        font-size: 13px;
        border-top: none;
        padding: 30px 16px;
        background-image: url("/assets/img/landing/slides/fondo_slide.jpg");
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
      }

      &.pricing-title {
        text-align: center;
        // border: solid 1px var(--primary);
        // background: var(--primary);
        // color: var(--white);
        padding: 10px;
        font-size: 2.3em;
        font-weight: 600;

        div {
          line-height: 1;
          font-size: .3em;
          font-weight: 500;
        }
      }
    }
  }

  .todoslosplanes {
    color: #ffffff;
    width: 80%;
    padding: 1.2rem 2rem;
    font-size: 15px;
    background: linear-gradient(to right, #2846a0, #14285a);
    margin: 2rem auto;
  }

  .media-body {
   p.descripcion {
     color: var(--gray);
     font-size: .8em;
     line-height: 1.25;
     font-weight: 400;
   }
 }
  .circle-check {
    width: 25px;
  }

  .pricing-plan .pricing-price span {
    font-weight: 700;
    color: var(--primary);
  }

  .font-size-20 {
    font-size: 20px;
  }

  .font-size-25 {
    font-size: 25px;
  }

  .font-size-39 {
    font-size: 39px;
    font-weight: 500;
  }

  .testimonials {
    padding-top: 80px;
    padding-bottom: 90px;
    background-color: var(--primary);
    background-image: url("/assets/img/landing/avatar_all.png");
  }

  .big-icon {
    font-size: 56px !important;
  }

  .features .big-icon {
    color: var(--primary) !important;
  }

  .features-icon {
    width: 125px;
  }

  .contact {
    // background-image: url("/assets/img/landing/fondo_footer.png");
    // // background-image: url("/assets/img/landing/word_map.png");
    // background-position: 50% 50%;
    // background-repeat: no-repeat;
    // margin-top: 60px;
  }

  .logo-footer {
    width: 240px;
  }

  .card-mensaje-contacto {
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
    padding: 10px 10px 1px 10px;
  }

  section.timeline {
    padding-bottom: 30px;
  }

  section.comments {
    padding-bottom: 80px;
  }

  .comments-avatar {
    margin-top: 25px;
    margin-left: 22px;
    margin-bottom: 25px;
  }

  .comments-avatar .commens-name {
    font-weight: 600;
    font-size: 14px;
  }

  .comments-avatar img {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .bubble {
    position: relative;
    height: 120px;
    padding: 20px;
    background: var(--white);
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    font-style: italic;
    font-size: 14px;
  }

  .bubble:after {
    content: '';
    position: absolute;
    border-style: solid;
    border-width: 15px 14px 0;
    border-color: var(--white) transparent;
    display: block;
    width: 0;
    z-index: 1;
    bottom: -15px;
    left: 30px;
  }

  /* RESPONSIVE CSS
  -------------------------------------------------- */
  @media (max-width: 1366px) {
    .carousel {
      height: 624px;
    }
  }

  @media (max-width: 1280px) {
    .carousel {
      height: 628px;
    }
  }

  @media (max-width: 1200px) {
    .header-back {
      background-color: var(--primary);
      background-image: url("/assets/img/landing/fondo.jpg");
      background-repeat: repeat;
      background-size: auto;
    }

    .contFondoTextura {
      background-color: var(--primary);
      background-image: url("/assets/img/landing/fondo.jpg");
      background-repeat: repeat;
      background-position: top;
      background-size: auto;
      height: auto;
      padding-bottom: 20px;
      margin-bottom: 15px;
    }

    .contFondoTexturaPlanes {
      background-color: var(--primary);
      background-image: url("/assets/img/landing/fondo.jpg");
      background-repeat: repeat;
      padding-bottom: 20px;
      margin-bottom: 15px;
    }

    .contFondoTexturaFooter {
      background-color: var(--primary);
      background-image: url("/assets/img/landing/fondo.jpg");
      background-repeat: repeat;
    }

    .todoslosplanes {
      background: none;
    }

    .features {
      .text-features-left{
        font-size: 1em;
      }

      .text-features-right{
        font-size: 1em;
      }
    }
  }

  @media (max-width: 1024px) {
    .header-back {
      background-position: center;
    }
  }

  @media (max-width: 992px) {
    #fondoFeatures {
      background-image: none;
    }

    .bgCirculo {
      display: none;
    }

    .carousel {
      height: 470px;
    }

    .cont-titlo-team {
      text-align: center;
    }

    .cont-redes-sociales {
      text-align: center;
    }

    address {
      text-align: center;
    }
    .features {
      .text-features-left{
        font-size: .9em;
      }

      .text-features-right{
        font-size: .9em;
      }
    }
  }
  // para ocultar boton Agendate app del header
  @media (min-width: 768px) and (max-width: 999px) {
    .container {
      a.navbar-brand{
        display: none;
      }
    }
  }

  @media (min-width: 768px) {

    /* Navbar positioning foo */
    .navbar-wrapper {
      //margin-top: 0;
    }

    .navbar-wrapper .container {
      padding-right: 15px;
      padding-left: 15px;
    }

    .navbar-wrapper .navbar {
      padding-right: 0;
      padding-left: 0;
    }

    /* The navbar becomes detached from the top, so we round the corners */
    .navbar-wrapper .navbar {
      border-radius: 4px;
    }

    .featurette-heading {
      font-size: 50px;
    }
  }

  @media (max-width: 992px) {
    .carousel-image {
      display: none;
    }

    .carousel-caption {
      h1 {
        font-size: 30px;
      }

      &.caption-responsive {
        left: 0 !important;
        bottom: auto !important;
        right: auto !important;
        text-align: left !important;
      }
    }
  }

  @media (max-width: 768px) {

    .carousel-caption,
    .carousel-caption.blank {
      left: 5%;
      top: 80px;
    }

    .carousel-caption h1 {
      font-size: 28px;
    }

    /* Bump up size of carousel content */
    .carousel-caption p {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 1.4;
    }

    .navbar.navbar-scroll .navbar-brand {
      margin-top: 6px;
    }

    .navbar-default {
      background-color: var(--white);
      border-color: var(--white);
      padding: 15px 0;
    }

    .navbar-default .navbar-nav>.active>a:focus {
      background: transparent;
      color: inherit;
    }

    .navbar-default .nav li a {
      color: var(--text-color);
    }

    .navbar-default .nav li a:hover {
      color: var(--primary);
    }

    .navbar-wrapper .navbar {
      padding-top: 0;
      padding-bottom: 5px;
      border-bottom: 1px solid var(--border-color);
      border-radius: 0;
    }

    .nav>li>a {
      padding: 10px 10px 15px 10px;
    }

    .navbar-nav>li>a {
      padding: 20px 10px;
    }

    .navbar .navbar-brand {
      margin-top: 5px;
      border-radius: 5px;
      font-size: 12px;
      padding: 10px;
      height: auto;
    }

    .navbar-wrapper .navbar {
      padding-left: 15px;
      padding-right: 5px;
    }

    .navbar-default .navbar-nav>.active>a,
    .navbar-default .navbar-nav>.active>a:hover {
      color: inherit;
    }

    .carousel-control {
      display: none;
    }

    .items-img-descripcion {
      img {
        &.team {
          width: 120px;
        }
      }

      .cont-descripcion {

        &.team-des {
          margin-left: 130px;
          margin-top: 10px;

          .cargo-team {
            font-size: .7em;
          }

          .nombre-team {
            font-size: .90em;
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    h1 {
      font-size: 25px;
    }

    .items-img-descripcion {
      display: inline-block;

      &.teamAgendate {
        margin-left: 120px;
      }

      .cont-descripcion {
        &.team-des {
          .cargo-team {
            font-size: .8em;
          }

          .nombre-team {
            font-size: .99em;
          }
        }
      }
    }
  }

  @media (max-width: 450px) {
    .items-img-descripcion {
      &.teamAgendate {
        margin-left: 80px;
      }
    }
  }

  @media (max-width: 400px) {
    .items-img-descripcion {
      &.teamAgendate {
        margin-left: 35px;
      }
    }
  }

  @media (min-width: 992px) {
    .featurette-heading {
      margin-top: 120px;
    }
  }

  @media (max-width: 767px) {
    .navbar .navbar-header {
      display: block;
      float: none;
    }

    .navbar .navbar-header .navbar-toggle {
      background-color: var(--white);
      padding: 9px 10px;
      border: none;
    }

    .nav.navbar-right {
      flex-direction: column;
    }
  }

  .navbar-toggle {
    color: #ddd;
    float: right;

    i {
      font-size: 24px;
    }
  }

}

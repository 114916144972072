.bg {
  &-custom {

    &-login {
      background-image: url("/assets/img/bg-login.jpg");
    }

    &-register {
      background-image: url("/assets/img/bg-login.jpg");
    }

    &-login,
    &-register,
    &-agendar-citas {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      // background-repeat: repeat;
      // background-size: auto;
    }
  }

  &-white{
    background: var(--white);
  }

  &-black{
    background: var(--black);
  }

  &-dark-gray-2{
    background: var(--dark-gray-2);
  }
}

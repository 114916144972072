@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;500;700;800&display=swap');

.titulos {
  color: var(--primary);
  font-weight: var(--font-family-extra-bold);
  line-height: 1;
  font-size: 2em;
  @media(max-width: 576px) {
    font-size: 1.5em;
  }
}

.subtitulos {
  color: var(--primary);
  font-weight: var(--font-family-semi-bold);
  // font-size: 12px;
}

.parrafos {
  font-weight: var(--font-family-regular);
}

.text-negrilla {
  font-weight: bold !important;
}

.text-primary {
  color: var(--primary) !important;
}

.text-info {
  color: var(--info) !important;
}

.icon-circulo-azul:before {
  color: var(--primary) !important;
}

.logoCliente{
  // min-height: 80px;
  max-height: 115px;
}

.cont-metodospago{
  text-align: center;
  img{
    margin: 0.25rem;
    width: 50px;
  }
}

textarea{
  resize: none;
}

.outline{
  outline: none;
}

.cursor{
  &-pointer{
    cursor: pointer;
  }
}

.forms {
  label.labels {
    color: var(--primary);
    font-weight: var(--font-family-medium);
    font-size: .7em;
    line-height: 1;
    margin-bottom: 0 !important;

    span.icon-label {
      background: -webkit-linear-gradient(#4FACFE, #00F2FE);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 1.4em;
    }

    span.icon-label-solid {
      background: var(--primary);
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 1.4em;
    }
  }
}

/*Error campos de formularios*/
.error-message {
  color: var(--red);
  font-size: 10px;
  margin-left: 0px;
  margin-top: 5px;
}

.bg-info {
  background-color: var(--info) !important;
}

.linea {
  margin: 5px 0;
  height: 1px;
  border-bottom: 6px solid var(--primary);
  display: inline-block;
  &-60{
    width: 60px;
  }

  &-100{
    width: 100px;
  }

  &-160{
    width: 160px;
  }
}

.h-vh-100 {
  height: 100vh;
}

.h-vh-100.scroll {
  height: 100vh;
  overflow: auto;
}

/* Buttons - only primary custom button
  ------------------------- */
.btn {
  font-size: 1em;
  // padding: 10px 20px;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
  color: var(--white);
}

.btn-primary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: var(--white) !important;
  // font-weight: 600;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-color: var(--info);
  border-color: var(--info);
  color: var(--white);
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary.active[disabled],
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
  background-color: #609af5;
  border-color: #609af5;
}

.btn-white {
  background-color: var(--white);
  border-color: var(--white);
  color: var(--info);
  // font-weight: 600;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
  background-color: var(--info);
  border-color: var(--info);
  color: var(--white);
}

.btn-info {
  background-color: var(--info);
  border-color: var(--info);
  color: var(--white);
  // font-weight: 600;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-color: var(--white);
  border-color: var(--info);
  color: var(--info);
}

/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/scss/bootstrap";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "theme/style";

body {
  // font-family: var(--font-family-poppins) !important;
  margin: 0;
  font-size: .9rem;
  // font-weight: 400;
  line-height: 1.5;
}

img {
  height: auto;
  max-width: 100%;
}

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?o1ogsa');
  src:  url('fonts/icomoon.eot?o1ogsa#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?o1ogsa') format('truetype'),
    url('fonts/icomoon.woff?o1ogsa') format('woff'),
    url('fonts/icomoon.svg?o1ogsa#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-circulo-azul:before {
  content: "\e911";
  color: #1049a2;
}
.icon-circulo-gris:before {
  content: "\e912";
  color: #e1e1e1;
}
.icon-whatsapp:before {
  content: "\e910";
}
.icon-agendaCancelarCita:before {
  content: "\e900";
}
.icon-agendaConfirmadaCita:before {
  content: "\e901";
}
.icon-agendaNewCita:before {
  content: "\e902";
}
.icon-agendaPorConfirmarCita:before {
  content: "\e903";
}
.icon-businessCards:before {
  content: "\e904";
}
.icon-calendar:before {
  content: "\e905";
}
.icon-clock:before {
  content: "\e906";
}
.icon-codeRef:before {
  content: "\e907";
}
.icon-envelope:before {
  content: "\e908";
}
.icon-key:before {
  content: "\e909";
}
.icon-locationPoint:before {
  content: "\e90a";
}
.icon-note:before {
  content: "\e90b";
}
.icon-phone:before {
  content: "\e90c";
}
.icon-phoneFijo:before {
  content: "\e90d";
}
.icon-user_blanck:before {
  content: "\e90e";
  color: #fff;
}
.icon-user:before {
  content: "\e90f";
}

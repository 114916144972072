.calendar[type="date"]::-webkit-calendar-picker-indicator {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  color: transparent;
  background: 0 0;
  margin: 0;
  opacity: 0;
  pointer-events: auto;
  z-index: 100;
}

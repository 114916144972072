:root{
  // fuentes
  --font-family-poppins: 'Poppins', sans-serif;
  --font-family-extra-bold: 800;
  --font-family-semi-bold: 600;
  --font-family-medium: 500;
  --font-family-regular: 400;
  --font-family-light: 300;
  --font-family-extra-light: 200;
  --font-family-extra-thin: 100;

  // Basic Colors
  --primary: #1049A2 !important; // Primary color
  --secondary: #9A9A9A !important; // secondary color
  --dark-gray: #c2c2c2 !important; // Default color
  --dark-gray-2: #f4f6f9;
  --blue: #1c84c6 !important; // Success color
  --info: #01BEE2 !important; // Info color
  --yellow: #f8ac59 !important; // Warning color
  --red: #E73119 !important; // Danger color
  --black: #000000 !important;
  --white: #FFFFFF !important;

  // Various colors
  --text-color: #676a6c; // Body text
  --gray: #999999; // Background wrapper color
  --light-gray: #D1DADE; // Default label, badge
  --label-badge-color: #5E5E5E;
  --light-blue: #f3f6fb;

  // Spinner color and margin
  --spin-color: --primary;
  --spin-margin: 0 auto;

  //Basics
  --basic-link-color: #337ab7;

  // IBOX colors ( default panel colors)
  --border-color: #e7eaec; // IBox border
  --ibox-title-bg: #ffffff; // IBox Background header
  --ibox-content-bg: #ffffff; // IBox Background content

  //Sidebar width
  --sidebar-width: 220px;

  // Boxed layout width
  --boxed-width: 1200px;
  // --boxed-background: image-url('patterns/shattered.png');

  //Border radius for buttons
  --btn-border-radius: 3px;

  //Navigation
  --nav-bg: #2F4050;
  // --nav-profile-pattern: image-url("patterns/header-profile.png");
  --nav-text-color: #a7b1c2;
}

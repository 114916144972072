.font{
  &-size{
    &-1_1{
      font-size: 1.1em !important;
    }

    &-1_2{
      font-size: 1.2em !important;
    }

    &-1_3{
      font-size: 1.3em !important;
    }
  }

  &-weight{
    &-semibold{
      font-weight: var(--font-family-semi-bold) ;
    }
    &-medium{
      font-weight: var(--font-family-medium) ;
    }
  }
}

.text {
  color: black;
  font-weight: normal;
  //font-size
  &--m	      { font-size: .3em !important; }
  &--s	      { font-size: .4em !important; }
  &--sl       { font-size: .5em !important; }
  &--x	      { font-size: .55em !important; }
  &--xs	      { font-size: .65em !important; }
  &--xm 		  { font-size: .7em !important; }
  &--xl       { font-size: .8em !important; }
  &--xls      { font-size: .85em !important; }
  &--sm 		  { font-size: .9em !important; }
  &--sml 		  { font-size: 1em !important; }
  &--md 		  { font-size: 1.2em !important; }
  &--lg 		  { font-size: 1.3em !important; }
  &--lgx 		  { font-size: 1.4em !important; }
  &--lgxs 	  { font-size: 1.5em !important; }
  &--lgxsm 	  { font-size: 1.6em !important; }
  &--lgxm 	  { font-size: 1.8em !important; }
  &--lgxl 	  { font-size: 1.9em !important; }
  &--lgxls 	  { font-size: 2em !important; }
  &--lgsm 	  { font-size: 2.2em !important; }
  &--lgsmd 	  { font-size: 2.5em !important; }
  &--lgsmlg   { font-size: 2.8em !important; }
  &--lgmd 	  { font-size:   3em !important; }
  &--lglg	    { font-size:   4em !important; }
  &--xlx	    { font-size:   5em !important; }
  &--xlxs	    { font-size:   6em !important; }
  &--xlxm     { font-size:   7em !important; }
  &--xlxl     { font-size:   8em !important; }
  &--xlsm     { font-size:   9em !important; }
  &--xlmd     { font-size:   10em !important; }
  &--xiii     { font-size:   13em !important; }
  &--xiv      { font-size:   14em !important; }
  &--xv       { font-size:   15em !important; }

  //font-weight
  &--lighter    { font-weight: 100; }
  &--thin 	    { font-weight: 200; }
  &--light 	    { font-weight: 300; }
  &--normal     { font-weight: 400; }
  &--medium     { font-weight: 500; }
  &--semibold   { font-weight: 600; }
  &--bold 	    { font-weight: 700; }
  &--extrabold  { font-weight: 800; }
  &--megabold   { font-weight: 900; }

  //font-style
  &--italic 	  { font-style: italic; }
  &--upper      { text-transform: uppercase; }
  &--lower      { text-transform: lowercase; }

  //letter-spacing
  &--lh-1       { line-height: 1; }
  &--lh-normal  { line-height: normal; }
  &--ls-1       { letter-spacing: -1px; }

  //text-decoration
  &--decoration{
    &-line-through { text-decoration: line-through; }
    &-underline { text-decoration: underline; }
    &-none { text-decoration: none; }
  }

  //font-color
  // &--white { color: $white; }
  // &--black { color: $black; }
  &--red { color: var(--red) }
  // &--blue  {
  //   color: $blue-text !important;
  //   &-fb      { color: $blue-fb; }
  //   &-sea     { color: $blue-sea; }
  //   &-medium  { color: $blue-medium !important; }
  //   &-twitter { color: $blue-twitter !important; }
  // }
  // &--gray {
  //   color: $gray-text !important;
  //   &-border  { color: $gray-border !important; }
  //   &-shadow  { color: $gray-shadow !important; }
  // }
  // &--orange       { color: $orange-text; }
  // &--yellow-start { color: $yellow-start !important; }
  // &--opacity { opacity: .60; }

  //font-family
  // &--muli 	    { font-family: $muli; }
  // &--lato       { font-family: $lato; }
  // &--heebo      { font-family: $heebo; }
  // &--awesome    { font-family: $awesome; }
  // &--oswald     { font-family: $oswald; }
  // &--work-sans  { font-family: $work-sans; }
}
